.main-body {
  display: flex;
  height: 100%;
  margin: 20px;
  overflow: hidden;
}

/* The 2:1 ratio layout for larger screens */
.larger-section {
  flex: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 30px;
  margin-top: 0;
}

.smaller-section {
  flex: 1;
  /* background-color: #ffffff; Optional */
}

/* Responsive design: Stack sections vertically on smaller screens */
@media (max-width: 768px) {
  .main-body {
    flex-direction: column;
  }

  .larger-section,
  .smaller-section {
    flex: none; /* Reset flex behavior */
    width: 100%; /* Full width for each section */
    height: auto; /* Adjust height for stacking */
    margin: 0;
  }
}

.prose-body p{
  color: #4b5563;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: start;
  margin-bottom: 1rem;
}



.bg-animated {
  background-image: url('../../assets/form_cover.png');
  background-position: center;
  /* background-repeat: no-repeat; */
  background-size: cover;
}


/* Add this CSS */
.ripple {
  position: relative;
  overflow: hidden;
}

.ripple:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.5);
  opacity: 0;
  transform: scale(0);
  transition: transform 0.5s, opacity 1s;
}

.ripple:active:after {
  opacity: 1;
  transform: scale(2);
}

