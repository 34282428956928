.main_header {
    background-image: url('../assets/new_bg.png');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.get_button {
    background-color: rgb(251 89 11);
}
  